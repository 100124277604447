import { isEqual } from 'lodash';
import { Store } from 'redux';
import {
  getRelatedPosts,
  getRelatedPostsMetadata,
} from '@wix/ambassador-blog-frontend-adapter-public-v2-related-post/http';
import {
  GetRelatedPostsMetadataResponse,
  GetRelatedPostsResponse,
  Metrics,
  RelatedPost,
  RelatedPostMetadata,
} from '@wix/ambassador-blog-frontend-adapter-public-v2-related-post/types';
import setPosts from '../../common/actions/set-posts';
import {
  getLanguageCode,
  getQueryLocale,
} from '../../common/selectors/locale-selectors';
import {
  enhancePostsWithMetadata,
  normalizePosts,
} from '../../common/services/post-utils';
import { setIsLoading } from '../../common/store/is-loading/is-loading-actions';
import { getRelatedPostCount } from '../selectors/related-posts-selectors';
import { getMainPost } from '../store/main-post';
import { AppState } from '../store/reducers';
import { RelatedPostsThunkAction } from '../types';

export const RELATED_POSTS_ENTITY = 'related-posts';

export function listenToMainPostChanges(store: Store) {
  const buildState = () => {
    return {
      mainPostId: getMainPost(store.getState())?.id,
      postCount: getRelatedPostCount(store.getState()),
    };
  };

  let prevState = buildState();

  store.subscribe(async () => {
    const currentState = buildState();
    if (currentState.mainPostId && !isEqual(prevState, currentState)) {
      prevState = currentState;
      await store.dispatch(fetchRelatedPostsRenderModel());
    }
  });
}

export function fetchRelatedPostsRenderModel(): RelatedPostsThunkAction {
  return async (dispatch, getState, { httpClient }) => {
    const state = getState();

    dispatch(setIsLoading(RELATED_POSTS_ENTITY, undefined, true));

    const mainPost = getMainPost(state);
    if (!mainPost) {
      dispatch(setPosts([]));
      dispatch(setIsLoading(RELATED_POSTS_ENTITY, undefined, false));
      return;
    }

    const locale = getQueryLocale(state);
    const languageCode = getLanguageCode(state);
    const pageSize = getRelatedPostCount(state);

    const [postsResponse, metadataResponse] = await Promise.all([
      httpClient.request(
        getRelatedPosts({
          postId: mainPost.id,
          languageCode,
          locale,
          pageSize,
          translationsName: 'related-posts-widget',
          includeInitialPageData: true,
        }),
      ),
      httpClient.request(
        getRelatedPostsMetadata({
          postId: mainPost.id,
          languageCode,
          pageSize,
        }),
      ),
    ]);

    const posts = transformResponse(
      state,
      postsResponse.data,
      metadataResponse.data,
    );
    dispatch(setPosts(posts));
    dispatch(setIsLoading(RELATED_POSTS_ENTITY, undefined, false));
  };
}

function transformResponse(
  state: AppState,
  postsResponse: GetRelatedPostsResponse,
  metadataResponse: GetRelatedPostsMetadataResponse,
) {
  const posts = enhancePostsWithMetadata(
    postsResponse.relatedPosts ?? [],
    mapMetadataByPostId(metadataResponse.relatedPostsMetadata ?? []),
  );

  const postsWithCoverMedia = applyCoverMedia(posts);

  const normalizedPosts = normalizePosts({
    state,
    posts: postsWithCoverMedia as any,
    blogCategoryIds: [],
    origin: '/v3/posts',
  });

  return normalizedPosts;
}

function applyCoverMedia(posts: RelatedPost[]) {
  return posts.map((_post) => ({
    ..._post,
    coverMedia: { ..._post.media, ..._post.media?.wixMedia },
  }));
}

function mapMetadataByPostId(metadata: RelatedPostMetadata[]) {
  return metadata.reduce<Record<string, Metrics>>((result, { id, metrics }) => {
    if (id && metrics) {
      result[id] = metrics;
    }
    return result;
  }, {});
}
